<template>
  <div class="uk-grid uk-grid-small" data-uk-grid>
    <div class="uk-width-1-1">
    <label class="uk-form-label">{{ label }}</label>
      <v-select 
        ref="select"
        append-to-body
        v-model="selected"
        :options="data"
        :filterable="false"
        @search="onSearch"
        label="fullname"
        @input="$emit('input', selected)"
        placeholder="Suchen..."
        :class="{ 'uk-form-danger': errors }"
        :clearable="false">
        <template slot="option" slot-scope="option">
          <span>
          {{ option.fullname }}
          </span><br>
          <span class="uk-text-small uk-text-light" v-if="option.customerNumber||option.technicianNumber">
            Nr.: {{ option.customerNumber||option.technicianNumber }}
          </span>
        </template>
        <template #selected-option="{ fullname, company }">
          <span v-if="company">{{ company }}</span>
          <span v-else>{{ fullname }}</span>
        </template>
          <template #spinner="{ loading }">
            <div v-if="loading" style="border-left-color: rgba(68,124,213,0.85)" class="vs__spinner">
            </div>
          </template>
          <template v-slot:no-options>
            <!--Keine Ergebnisse-->
            Tippen zum Suchen...
          </template>
        <template #list-footer v-if="type !== 'insuranceContact'">
          <li class=" uk-margin-small-top uk-text-small uk-background-muted" style="padding: 5px;">
            <a href="#" class="uk-link-reset uk-flex uk-flex-middle uk-flex-center" @click.prevent="createNew">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" style="width: 15px;">
                <defs/>
                <path d="M624 208h-64v-64c0-9-7-16-16-16h-32c-9 0-16 7-16 16v64h-64c-9 0-16 7-16 16v32c0 9 7 16 16 16h64v64c0 9 7 16 16 16h32c9 0 16-7 16-16v-64h64c9 0 16-7 16-16v-32c0-9-7-16-16-16zm-400 48a128 128 0 100-256 128 128 0 000 256zm90 32h-17a174 174 0 01-146 0h-17C60 288 0 348 0 422v42c0 27 22 48 48 48h352c27 0 48-21 48-48v-42c0-74-60-134-134-134z" fill="currentcolor"/>
              </svg>
              <span class="uk-margin-small-left"> Neu anlegen</span> 
            </a>
          </li>
        </template>
      </v-select>
    </div>
    <create-customer ref="createCustomerModal" :type="type" @created="onCustomerCreated"></create-customer>
  </div>
</template>
<script>
import CustomerService from '@/services/customer.service.js'
import TechnicianService from '@/services/technician.service.js'
import InsuranceService from '@/services/insurance.service.js'
const CreateCustomer = () => import('./CreateCustomerModal')
import vSelect from 'vue-select'
//import OpenIndicator from '@/components/select/OpenIndicator.vue'
//import Deselect from '@/components/select/Deselect.vue'

//vSelect.props.components.default = () => ({
//    OpenIndicator: OpenIndicator,
//    Deselect: Deselect
//  });

import _ from 'lodash'
export default {
  props: ['value', 'label', 'type', 'errors'],
  data() {
    return {
      data: [],  
      selected: null,
    }
  },
  components: {
    vSelect,
    CreateCustomer,
  },
  mounted() {
    if(this.type === 'customer') {
      this.getCustomers()
    } else if (this.type === 'insuranceContact') {
      this.getInsuranceContact()
    } else {
      this.getTechnicians()
    }
    
  },
  watch: {
    value() {
      this.getSelected()
    }
  },
  methods: {
    async getSelected() {
      let response = null
      if(this.type === 'customer') {
        response = await CustomerService.getList({customerNumber: this.value.customerNumber});
      } else if(this.type === 'insuranceContact') {
        response = await InsuranceService.getList({id: this.value.id});
      } else {
        response = await TechnicianService.getList({technicianNumber: this.value.technicianNumber});
      }
      
      this.selected = response.data.data[0]
      this.$emit('getSelected', this.selected)
    },
    async getCustomers() {
      const customers = await CustomerService.getList();
      this.data = customers.data.data
    },
    async getTechnicians() {
      const technicians = await TechnicianService.getList();
      this.data = technicians.data.data
    },
    async getInsuranceContact() {
      const insurance = await InsuranceService.getList();
      this.data = insurance.data.data
    },
    onSearch(search, loading) {
      if (search.length === 0) {
        return
      }
      loading(true);
      this.search(loading, search, this);
    },
    search: _.debounce((loading, search, vm) => {
      CustomerService.getList({query: escape(search)}).then(res => {
        vm.data = res.data.data
        loading(false);
      });
    }, 350),
    createNew() {
      this.$refs.createCustomerModal.open()
    },
    onCustomerCreated(data) {
      if(this.type === 'customer') {
        this.getCustomers()
      } else {
        this.getTechnicians()
      }
      this.selected = data
      this.$emit('input', this.selected)
      this.$refs.createCustomerModal.close()
    } 
  },
}
</script>