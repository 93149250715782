<template>
  <form class="uk-form-stacked">
    <div class="uk-grid uk-grid-small" data-uk-grid>
      <div class="uk-width-1-1">
        <label class="uk-form-label">Firma</label>
        <div class="uk-form-controls">
          <input
            class="uk-input uk-border-rounded"
            type="text"
            v-model="customer.company"
          />
        </div>
      </div>
      <div class="uk-width-1-1">
        <label class="uk-form-label">Anrede</label>
        <div class="uk-form-controls">
          <select class="uk-select uk-border-rounded" v-model="customer.salutation">
            <option value="Herr">Herr</option>
            <option value="Frau">Frau</option>
          </select>
        </div>
      </div>
      <div class="uk-width-1-2@l">
        <label class="uk-form-label">Vorname*</label>
        <div class="uk-form-controls">
          <input
            class="uk-input uk-border-rounded"
            type="text"
            v-model="customer.firstname"
          />
        </div>
      </div>
      <div class="uk-width-1-2@l">
        <label class="uk-form-label">Nachname*</label>
        <div class="uk-form-controls">
          <input
            class="uk-input uk-border-rounded"
            type="text"
            v-model="customer.lastname"
          />
        </div>
      </div>
      <div class="uk-width-1-1">
        <label class="uk-form-label">ASP</label>
        <div class="uk-form-controls">
          <input
            class="uk-input uk-border-rounded"
            type="text"
            v-model="customer.asp"
          />
        </div>
      </div>
      <div class="uk-width-1-1">
        <label class="uk-form-label">Straße*</label>
        <div class="uk-form-controls">
          <input
            class="uk-input uk-border-rounded"
            type="text"
            v-model="customer.street"
          />
        </div>
      </div>
      <div class="uk-width-1-2@l">
        <label class="uk-form-label">PLZ*</label>
        <div class="uk-form-controls">
          <input
            class="uk-input uk-border-rounded"
            type="text"
            v-model="customer.zipcode"
          />
        </div>
      </div>
      <div class="uk-width-1-2@l">
        <label class="uk-form-label">Ort*</label>
        <div class="uk-form-controls">
          <input
            class="uk-input uk-border-rounded"
            type="text"
            v-model="customer.city"
          />
        </div>
      </div>
      <div class="uk-width-1-1">
        <label class="uk-form-label">E-Mail</label>
        <div class="uk-form-controls">
          <input
            class="uk-input uk-border-rounded"
            type="text"
            v-model="customer.email"
          />
        </div>
      </div>
      <div class="uk-width-1-1">
        <label class="uk-form-label">Telefon</label>
        <div class="uk-form-controls">
          <input
            class="uk-input uk-border-rounded"
            type="text"
            v-model="customer.phone"
          />
        </div>
      </div>
      <div class="uk-width-1-1">
        <label class="uk-form-label">Fax</label>
        <div class="uk-form-controls">
          <input
            class="uk-input uk-border-rounded"
            type="text"
            v-model="customer.fax"
          />
        </div>
      </div>
      <div class="uk-width-1-1">
        <label class="uk-form-label">Mobil</label>
        <div class="uk-form-controls">
          <input
            class="uk-input uk-border-rounded"
            type="text"
            v-model="customer.mobile"
          />
        </div>
      </div>
      <div class="uk-width-1-1" v-if="mapLink">
        <iframe id="googleiframe" style="display: block; width:100%; height:400px;"
        :src="mapLink"
        frameborder="0" scrolling="no" marginheight="0" marginwidth="0" >
        </iframe>

      </div>
    </div>
  </form>
</template>

<script>
import _ from 'lodash'
export default {
  props: {
    customer: {
      required: true
    }
  },
  data() {
    return {
      mapLink: null
    }
  },
  watch: {
    customer: {
      handler(val) {
        this.$emit('input', val)
        this.generateMapLink()
      },
      deep: true
    },
  },
  methods: {
    generateMapLink: _.debounce(function () {
      this.mapLink = `https://maps.google.de/maps?hl=de&q=${this.customer.street}, ${this.customer.zipcode} ${this.customer.city}, Deutschland&ie=UTF8&t=&z=13&iwloc=B&output=embed&key=AIzaSyCsa2zfyXQsQkHgkCW7dja9YQtuPtnW8hU`
    }, 1000)
  }
}
</script>
