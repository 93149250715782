import Api from './api.service'

export default {
  getTypes() {
    return Api().get(`document/types`)
  },
  delete (id) {
    return Api().delete(`document/${id}`)
  }
}
