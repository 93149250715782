<template>
  <form class="uk-form-stacked">
    <div class="uk-grid uk-grid-medium uk-child-width-1-3@l uk-child-width-1-2@m" data-uk-grid>
      <div>
        <label>1. Termin</label>
        <div class="uk-form-controls">
          <date-picker
            is-expanded
            mode="date"
            v-model="form.date1"
            :attributes="datepickerAttr"
          >
          </date-picker>
        </div>
      </div>
      <div>
        <label><input class="uk-checkbox uk-text-small" type="checkbox" v-model="form.secondDate"> 2. Termin</label>
        <template v-if="form.secondDate">
          <div class="uk-form-controls">
          <date-picker
            is-expanded
            mode="date"
            v-model="form.date2"
            :attributes="datepickerAttr"
          >
          </date-picker>
        </div>
        </template>
      </div>
      <div v-if="form.secondDate">
        <label><input class="uk-checkbox uk-text-small" type="checkbox" v-model="form.thirdDate"> 3. Termin</label>
        <template v-if="form.thirdDate">
          <div class="uk-form-controls">
          <date-picker
           is-expanded
            mode="date"
            v-model="form.date3"
            :attributes="datepickerAttr"
          >
          </date-picker>
        </div>
        </template>
      </div>
    </div>
  </form>
</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import { maska } from 'maska'
export default {
  props: {
    value: {
      required: true,
    },
  },
  data() {
    return {
      datepickerAttr: [
        {
          dot: true,
          dates: new Date(),
        },
      ],
      timeModelConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
      form: {
        secondDate: false,
        thirdDate: false,
        date1: new Date(),
        time1from: '',
        time1to: '',
        travelTime1: '',
        date2: new Date(),
        time2from: '',
        time2to: '',
        travelTime2: '',
        date3: new Date(),
        time3from: '',
        time3to: '',
        travelTime3: '',
      },
    }
  },
  components: {
    DatePicker
  },
  directives: { maska },
  mounted() {
    setTimeout(() => {
      this.form = Object.assign({}, this.form, this.value)
    }, 500)
  },
  watch: {
    form: {
      handler() {
        this.$emit('input', this.form)
      },
      deep: true,
    },
  },
  /*
  computed: {
    time1from: {
      get() {
        return this.form.date1
      },
      set(value) {
        this.form.time1from = value
      }
    },
    time1To: {
      get() {
        return this.form.date1
      },
      set(value) {
        this.form.time1to = value
      }
    },
    time2from: {
      get() {
        return this.form.date2
      },
      set(value) {
        this.form.time2from = value
      }
    },
    time2To: {
      get() {
        return this.form.date2
      },
      set(value) {
        this.form.time2to = value
      }
    },
    time3from: {
      get() {
        return this.form.date3
      },
      set(value) {
        this.form.time3from = value
      }
    },
    time3To: {
      get() {
        return this.form.date3
      },
      set(value) {
        this.form.time3to = value
      }
    }
  }
  */
}
</script>
