<template>
  <form class="uk-form-stacked">
    <div class="uk-margin">
      <label class="uk-form-label">Anlass</label>
      <div class="uk-form-controls">
        <textarea
          class="uk-textarea uk-border-rounded"
          rows="5"
          v-model="form.reason"
        ></textarea>
      </div>
    </div>
    <div class="uk-margin">
      <label class="uk-form-label">Feststellungen</label>
      <div class="uk-form-controls">
        <textarea
          class="uk-textarea uk-border-rounded"
          rows="5"
          v-model="form.findings"
        ></textarea>
      </div>
    </div>
    <div class="uk-margin">
      <label class="uk-form-label">Ergebnis</label>
      <div class="uk-form-controls">
        <textarea
          class="uk-textarea uk-border-rounded"
          rows="5"
          v-model="form.result"
        ></textarea>
      </div>
    </div>
    <div class="uk-margin">
      <label class="uk-form-label">Nächste Schritte</label>
      <div class="uk-form-controls">
        <textarea
          class="uk-textarea uk-border-rounded"
          rows="5"
          v-model="form.nextSteps"
        ></textarea>
      </div>
    </div>
    <div class="uk-margin">
      <div class="uk-grid uk-grid-small uk-child-width-1-4@l uk-child-width-1-2@m" data-uk-grid>
        <div>
          <label class="uk-form-label">Schadenursache gefunden?</label>
          <div class="uk-grid-small uk-child-width-auto uk-grid">
            <label><input class="uk-radio" type="radio" v-model="form.reasonFound" value="Ja" /> Ja</label>
            <label><input class="uk-radio" type="radio" v-model="form.reasonFound" value="Nein" /> Nein</label>
          </div>
        </div>
        <div>
          <label class="uk-form-label">Schadenursache freigelegt?</label>
          <div class="uk-grid-small uk-child-width-auto uk-grid">
            <label><input class="uk-radio" type="radio" v-model="form.reasonExposed" value="Ja" /> Ja</label>
            <label><input class="uk-radio" type="radio" v-model="form.reasonExposed" value="Nein" /> Nein</label>
          </div>
        </div>
        <div>
          <label class="uk-form-label">Notfallabdichtung durchgeführt?</label>
          <div class="uk-grid-small uk-child-width-auto uk-grid">
            <label><input class="uk-radio" type="radio" v-model="form.notfallabdichtung" value="Ja" /> Ja</label>
            <label><input class="uk-radio" type="radio" v-model="form.notfallabdichtung" value="Nein" /> Nein</label>
          </div>
        </div>
        <div>
          <label class="uk-form-label">Trocknung empfohlen?</label>
          <div class="uk-grid-small uk-child-width-auto uk-grid">
            <label><input class="uk-radio" type="radio" v-model="form.trocknung" value="Ja" /> Ja</label>
            <label><input class="uk-radio" type="radio" v-model="form.trocknung" value="Nein" /> Nein</label>
          </div>
        </div>
        <div>
          <label class="uk-form-label">Sanierung empfohlen?</label>
          <div class="uk-grid-small uk-child-width-auto uk-grid">
            <label><input class="uk-radio" type="radio" v-model="form.sanierung" value="Ja" /> Ja</label>
            <label><input class="uk-radio" type="radio" v-model="form.sanierung" value="Nein" /> Nein</label>
          </div>
        </div>
        <div>
          <label class="uk-form-label">Installateur zu beauftragen?</label>
          <div class="uk-grid-small uk-child-width-auto uk-grid">
            <label><input class="uk-radio" type="radio" v-model="form.installateur" value="Ja" /> Ja</label>
            <label><input class="uk-radio" type="radio" v-model="form.installateur" value="Nein" /> Nein</label>
          </div>
        </div>
        <div>
          <label class="uk-form-label">Ersatzfliesen vorhanden?</label>
          <div class="uk-grid-small uk-child-width-auto uk-grid">
            <label><input class="uk-radio" type="radio" v-model="form.ersatzfliesen" value="Ja" /> Ja</label>
            <label><input class="uk-radio" type="radio" v-model="form.ersatzfliesen" value="Nein" /> Nein</label>
          </div>
        </div>
        <div>
          <label class="uk-form-label">Rückbau/Gewerke erforderlich?</label>
          <div class="uk-grid-small uk-child-width-auto uk-grid">
            <label><input class="uk-radio" type="radio" v-model="form.rueckbau" value="Ja" /> Ja</label>
            <label><input class="uk-radio" type="radio" v-model="form.rueckbau" value="Nein" /> Nein</label>
          </div>
        </div>
      </div>
    </div>
    <div class="uk-margin">
      <div class="uk-grid uk-grid-small uk-child-width-1-4@l uk-child-width-1-2@m" data-uk-grid>
        <div>
          <label><input class="uk-checkbox" type="checkbox" v-model="form.deckeBoden"/> Decke/Boden</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <span class="uk-form-icon uk-form-icon-flip uk-text-small" style="width: 40px;">m²</span>
              <input type="text" class="uk-input uk-border-rounded" v-model="form.deckeBodenM"
              :disabled="!form.deckeBoden">
            </div>
          </div>
        </div>
        <div>
          <label><input class="uk-checkbox" type="checkbox" v-model="form.wand"/> Wand</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <span class="uk-form-icon uk-form-icon-flip uk-text-small" style="width: 40px;">m²</span>
              <input type="text" class="uk-input uk-border-rounded" v-model="form.wandM"
              :disabled="!form.wand">
            </div>
          </div>
        </div>
        <div>
          <label><input class="uk-checkbox" type="checkbox" v-model="form.raumtrocknung"/> Raumtrocknung</label>
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <span class="uk-form-icon uk-form-icon-flip uk-text-small" style="width: 75px;">Räume</span>
              <input type="text" class="uk-input uk-border-rounded" v-model="form.raumtrocknungM"
              :disabled="!form.raumtrocknung">
            </div>
          </div>
        </div>
        <div>
          <label><input class="uk-checkbox" type="checkbox" v-model="form.sonstiges"/> Sonstiges</label>
          <div class="uk-form-controls">
            <input type="text" class="uk-input uk-border-rounded" v-model="form.sonstigesM"
            :disabled="!form.sonstiges">
          </div>
        </div>
      </div>
    </div>
    <div class="uk-margin">
      <div class="uk-grid uk-grid-small uk-child-width-1-4@l uk-child-width-1-2@m" data-uk-grid>
        <div>
          <div class="uk-form-controls">
            <label><input class="uk-checkbox" type="checkbox" v-model="form.wohnzimmer"> Wohnzimmer</label><br>
            <label><input class="uk-checkbox" type="checkbox" v-model="form.flur"> Flur</label>
          </div>
        </div>
        <div>
          <div class="uk-form-controls">
            <label><input class="uk-checkbox" type="checkbox" v-model="form.schlafzimmer"> Schlafzimmer</label><br>
            <label><input class="uk-checkbox" type="checkbox" v-model="form.bad"> Bad</label>
          </div>
        </div>
        <div>
          <div class="uk-form-controls">
            <label><input class="uk-checkbox" type="checkbox" v-model="form.kinderzimmer"> Kinderzimmer</label><br>
            <label><input class="uk-checkbox" type="checkbox" v-model="form.kueche"> Küche</label>
          </div>
        </div>
        <div>
          <div class="uk-form-controls">
            <label><input class="uk-checkbox" type="checkbox" v-model="form.buero"> Büro</label><br>
            <label><input class="uk-checkbox" type="checkbox" v-model="form.keller"> Keller</label>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
    },
  },
  data() {
    return {
      form: {
        reason: '',
        findings: '',
        result: '',
        nextSteps: '',
        reasonFound: 'Nein',
        reasonExposed: 'Nein',
        notfallabdichtung: 'Nein',
        trocknung: 'Nein',
        sanierung: 'Nein',
        installateur: 'Nein',
        ersatzfliesen: 'Nein',
        rueckbau: 'Nein'
      },
    }
  },
  mounted() {
    setTimeout(() => {
      this.form = Object.assign({}, this.form, this.value)
    }, 500)
  },
  watch: {
    form: {
      handler() {
        this.$emit('input', this.form)
      },
      deep: true,
    },
  },
}
</script>
