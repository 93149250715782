<template>
  <div>
    <template v-if="directUpload">
      <upload :url="finderUploadUrl" @uploaded="onFinderImageUploaded" :multiple="true" :path="`/${projectNumber}`"
        root="/files" :dropzone="true"></upload>
    </template>
    <template v-else>

      <a class="uk-placeholder uk-text-center uk-display-block uk-margin-bottom uk-link-reset" v-if="!source"
        @click.prevent="pick">
        <img width="60" height="60" alt="Placeholder Image" src="/img/placeholder-image.svg">
        <p class="uk-text-muted uk-margin-small-top">Bild auswählen</p>
      </a>
    </template>

    <div class="uk-inline uk-margin-bottom" v-if="source">
      <div class="uk-overlay-hover uk-visible-toggle">
        <img :src="source">

        <div class="uk-overlay-panel uk-overlay-background uk-overlay-fade"></div>
        <a class="uk-position-cover" @click="pick"></a>
        <div class="uk-position-top-right uk-invisible-hover" style="padding: 5px;">
          <a href="#" class="uk-text-danger uk-icon-button" uk-icon="icon: trash; ratio:1.2" @click.prevent="remove"></a>
        </div>
      </div>
    </div>
    <modal ref="modal" large>

      <div class="uk-modal-body uk-background-muted">
        <finder id="finder" ref="finder" mode="write" :path="'/' + (projectNumber || '')"></finder>
      </div>
      <div class="uk-modal-footer uk-text-right">
        <button class="uk-button uk-button-link uk-modal-close uk-margin-small-right" type="button">Abbrechen</button>
        <button class="uk-button uk-button-primary uk-border-rounded uk-button-small" type="button"
          :disabled="!hasSelection" @click.prevent="select">Übernehmen</button>
      </div>

    </modal>
  </div>
</template>
<script>
import Modal from '@/components/Modal'
import Finder from '@/components/finder/Index'
import Configuration from '@/configuration'
export default {
  props: {
    source: { default: '' },
    projectNumber: { default: null, required: false },
    directUpload: { required: false, default: false }
  },
  components: {
    Finder,
    Modal,
    upload: () => import('@/components/finder/Upload')
  },
  data() {
    return {
      localSource: '',
      finder: null
    }
  },
  mounted() {
    this.localSource = this.source
  },
  computed: {
    hasSelection() {
      if (this.finder) {
        let selected = this.finder.getSelected();
        return selected.length === 1 && this.finder.isImage(selected[0].url)
      }
      return false
    },
    finderUploadUrl() {
      return `${Configuration.value('apiUrl')}/finder/upload`
    },
  },
  methods: {
    pick() {
      this.$refs.modal.open();
      setTimeout(() => {
        this.finder = this.$refs.finder
      }, 500)

    },
    select() {
      const selected = this.$refs.finder.getSelected()[0]
      this.source = selected.url;
      this.$emit('image-selected', { url: this.source, ref: selected.ref });
      this.$refs.finder.removeSelection();
      this.$refs.modal.close();
    },
    remove() {
      this.source = ''
      this.$emit('image-removed');
    },
    onFinderImageUploaded(data) {
      console.log(data);
      this.source = data.url;
      this.$emit('image-selected', { url: this.source, ref: data.ref });
    }
  }
}
</script>