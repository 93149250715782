<template>
  <form class="uk-form-stacked">
    <div class="uk-margin">
      <div class="uk-grid uk-child-width-1-1" data-uk-grid>
        <div>
          <div class="uk-h5 uk-text-bold uk-margin-small">Trocknung von:</div>
          <div class="uk-grid uk-grid-small uk-child-width-1-2@m">
            <div>
              <div class="uk-form-controls">
                <label><input class="uk-checkbox" type="checkbox" v-model="form.schwimmenderEstrich" /> schwimmender Estrich</label><br />
                <label><input class="uk-checkbox" type="checkbox" v-model="form.mitFussbodenheitzung" /> mit Fußbodenheitzung</label><br />
                <label><input class="uk-checkbox" type="checkbox" v-model="form.ohneFussbodenheitzung" /> ohne Fußbodenheitzung</label>
              </div>
              <div class="uk-margin">
                <label class="uk-form-label">Dämmung</label>
                <div class="uk-form-controls">
                  <input class="uk-input uk-border-rounded" v-model="form.daemmung" />
                </div>
              </div>
              <div class="uk-grid uk-child-width-1-2@m" data-uk-grid>
                <div>
                  <label class="uk-form-label">Bodenbelag</label>
                  <div class="uk-form-controls">
                    <v-select :options="['Fliesen', 'Laminat', 'Parkett', 'Vinyl', 'Farbe']" v-model="form.bodenbelag" :clearable="false" :searchable="false"></v-select>
                  </div>
                </div>
                <div>
                  <label class="uk-form-label">Wandbelag</label>
                  <div class="uk-form-controls">
                    <v-select :options="['Tapete', 'Farbe', 'Putz']" v-model="form.wandbelag" :clearable="false" :searchable="false"></v-select>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="uk-form-controls">
                <label><input class="uk-checkbox" type="checkbox" v-model="form.decke" /> Decke</label><br />
                <label><input class="uk-checkbox" type="checkbox" v-model="form.boden" /> Boden</label><br />
                <label><input class="uk-checkbox" type="checkbox" v-model="form.wand" /> Wand</label><br />
                <label><input class="uk-checkbox" type="checkbox" v-model="form.raum" /> Raum</label>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="uk-h5 uk-text-bold uk-margin-small">Zustand bei Trocknungsbeginn:</div>
          <div class="uk-grid uk-grid-small uk-child-width-1-2@m">
            <div>
              <div class="uk-form-controls">
                <label><input class="uk-checkbox" type="checkbox" v-model="form.leichtFeucht" /> leicht feucht</label><br />
                <label><input class="uk-checkbox" type="checkbox" v-model="form.starkFeucht" /> stark feucht</label><br />
                <label><input class="uk-checkbox" type="checkbox" v-model="form.freiesWasser" /> freies Wasser</label>
              </div>
              <label><input class="uk-checkbox" type="checkbox" v-model="form.sonstiges" /> Sonstiges</label>
              <div class="uk-form-controls">
                <div class="uk-inline uk-width-1-1">
                  <input type="text" class="uk-input uk-border-rounded" v-model="form.sonstigesV" :disabled="!form.sonstiges" />
                </div>
              </div>
            </div>
            <div>
              <div class="uk-form-controls">
                <label><input class="uk-checkbox" type="checkbox" v-model="form.besonderheiten" /> Besonderheiten:</label>
                <div class="uk-form-controls">
                  <div class="uk-inline uk-width-1-1">
                    <input type="text" class="uk-input uk-border-rounded" v-model="form.besonderheitenV" :disabled="!form.besonderheiten" />
                  </div>
                </div>
              </div>
              <!--
              <div class="uk-margin-small">
                <label class="uk-form-label">Messgerät</label>
                <div class="uk-form-controls">
                  <input
                    class="uk-input uk-border-rounded"
                    v-model="form.messgeraet"
                  />
                </div>
              </div>
              -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import vSelect from 'vue-select'
export default {
  props: {
    value: {
      required: true,
    },
  },
  components: { vSelect },
  data() {
    return {
      form: {
        schwimmenderEstrich: false,
        mitFussbodenheitzung: false,
        ohneFussbodenheitzung: false,
        daemmung: 'Styropor',
        bodenbelag: null,
        wandbelag: null,
        decke: false,
        boden: false,
        wand: false,
        raum: false,
        leichtFeucht: false,
        starkFeucht: false,
        freiesWasser: false,
        sonstiges: false,
        sonstigesV: '',
        besonderheiten: false,
        besonderheitenV: '',
        messgeraet: '',
      },
    }
  },
  mounted() {
    setTimeout(() => {
      this.form = Object.assign({}, this.form, this.value)
    }, 500)
  },
  watch: {
    form: {
      handler() {
        this.$emit('input', this.form)
      },
      deep: true,
    },
  },
}
</script>
