<template>
  <form class="uk-form-stacked">
    <div class="uk-grid uk-grid-small uk-child-width-1-4@l uk-child-width-1-2@m" data-uk-grid>
      <div>
        <div class="uk-form-controls">
          <label><input class="uk-checkbox" type="checkbox" v-model="form.feuchtemesser"> Feuchtemesser</label><br>
          <label><input class="uk-checkbox" type="checkbox" v-model="form.druckpruefung"> Druckprüfung</label><br>
          <label><input class="uk-checkbox" type="checkbox" v-model="form.thermografie"> Thermografie</label><br>
          <label><input class="uk-checkbox" type="checkbox" v-model="form.horchgeraet"> Horchgerät</label><br>
          <label><input class="uk-checkbox" type="checkbox" v-model="form.rohrkamera"> Rohrkamera</label>
        </div>
      </div>
      <div>
        <div class="uk-form-controls">
          <label><input class="uk-checkbox" type="checkbox" v-model="form.endoskop"> Endoskop</label><br>
          <label><input class="uk-checkbox" type="checkbox" v-model="form.tracergas"> Tracergas</label>
        </div>
        <div class="uk-form-controls">
          <div class="uk-inline uk-width-1-1">
            <span class="uk-form-icon uk-form-icon-flip uk-text-small" style="width: 75px;">Liter</span>
            <input type="text" class="uk-input uk-border-rounded" v-model="form.tracergasM"
            :disabled="!form.tracergas">
          </div>
        </div>
        <div class="uk-form-controls uk-margin-small-top">
          <label><input class="uk-checkbox" type="checkbox" v-model="form.innenabdichter"> Innenabdichter</label>
        </div>  
      </div>
      <div>
        <div class="uk-form-controls">
          <label><input class="uk-checkbox" type="checkbox" v-model="form.flachdachmesstechnik"> Flachdachmesstechnik</label><br>
          <label><input class="uk-checkbox" type="checkbox" v-model="form.differenzdruckmessung"> Differenzdruckmessung</label><br>
          <label><input class="uk-checkbox" type="checkbox" v-model="form.hohlraumkamera"> Hohlraumkamera</label><br>
          <label><input class="uk-checkbox" type="checkbox" v-model="form.signalfarbe"> Signalfarbe</label><br>
          <label><input class="uk-checkbox" type="checkbox" v-model="form.leitungsortung"> Leitungsortung</label>
        </div>
      </div>
      <div>
        <div class="uk-form-controls">
          <label><input class="uk-checkbox" type="checkbox" v-model="form.hochdruckspueler"> Hochdruckspüler</label><br>
          <label><input class="uk-checkbox" type="checkbox" v-model="form.funkkorrelator"> Funkkorrelator</label><br>
          <label><input class="uk-checkbox" type="checkbox" v-model="form.signalnebel"> Signalnebel</label><br>
          <label><input class="uk-checkbox" type="checkbox" v-model="form.kompressor"> Kompressor</label>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
    },
  },
  data() {
    return {
      form: {
        feuchtemesser: false,
        druckpruefung: false,
        thermografie: false,
        horchgeraet: false,
        rohrkamera: false,
        endoskop: false,
        tracergas: false,
        tracergasM: '',
        innenabdichter: false,
        flachdachmesstechnik: false,
        differenzdruckmessung: false,
        hohlraumkamera: false,
        signalfarbe: false,
        leitungsortung: false,
        hochdruckspueler: false,
        signalnebel: false,
        kompressor: false
      },
    }
  },
  mounted() {
    setTimeout(() => {
      this.form = Object.assign({}, this.form, this.value)
    }, 500)
  },
  watch: {
    form: {
      handler() {
        this.$emit('input', this.form)
      },
      deep: true,
    },
  },
}
</script>
