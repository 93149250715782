<template>
  <div>
    <div class="uk-section uk-section-xsmall uk-padding-remove-top">
      <div data-uk-sticky="offset:52;media: @m" class="uk-background-default breadcrumb"
        style="padding: 15px 35px; border-bottom: 1px solid rgb(229, 229, 229); z-index: 9">
        <div class="uk-flex uk-flex-between uk-flex-middle uk-flex-wrap">
          <div class="uk-flex uk-flex-middle">
            <h3 class="uk-h5 uk-margin-remove uk-flex uk-flex-middle">
              <span class="uk-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="currentColor"
                  class="uk-margin-small-right">
                  <path
                    d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z">
                  </path>
                </svg>
              </span>
              {{ headerLabel }}
            </h3>
          </div>
          <div class="uk-visible@m">
            <div uk-margin>
              <a href="#" @click.prevent="$router.go(-1)"
                class="uk-button uk-button-text uk-margin-small-right">Schließen</a>
              <button v-if="form.id && ($can('manage', 'all') || $can('print', 'projects'))" uk-toggle="target: #print"
                type="button"
                class="uk-button uk-button-secondary uk-border-rounded uk-margin-small-right uk-button-small">
                <span data-uk-icon="print"></span>
                Drucken
              </button>
              <!--
              <template v-if="form.id && $can('manage', 'all')">
                <button class="uk-button uk-button-secondary uk-border-rounded uk-button-small uk-margin-small-right">
                  E-Mail senden
                  <span data-uk-icon="triangle-down"></span>
                </button>
                <div
                  data-uk-dropdown="animation: uk-animation-slide-top-small; duration: 200;delay-hide: 0;mode: click;pos: bottom-right;offset: 20">
                  <ul class="uk-nav uk-dropdown-nav">
                    <li>
                      <a class="uk-dropdown-close" href="#" @click.prevent="sendMail('insurance')">
                        Versicherungsunterlagen </a>
                    </li>
                    <li>
                      <a class="uk-dropdown-close" href="#" @click.prevent="sendMail('power-consumption')">
                        Stromverbrauch </a>
                    </li>
                  </ul>
                </div>
                <button @click.prevent="createOffer" type="button"
                  class="uk-button uk-button-secondary uk-border-rounded uk-margin-small-right uk-button-small">
                  <span data-uk-icon="tag"></span>
                  Angebot erstellen
                </button>
              </template>
            -->
              <button
                class="uk-button uk-button-primary uk-border-rounded uk-margin-small-right uk-width-auto@l uk-width-1-1 uk-button-small"
                type="submit" :disabled="sending" v-if="form.id" @click.prevent="handleSubmit(true)">
                Speichern und Schließen
              </button>
              <button class="uk-button uk-button-primary uk-border-rounded uk-width-auto@l uk-width-1-1 uk-button-small"
                type="submit" :disabled="sending" @click.prevent="handleSubmit(false)">
                Speichern
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="uk-container uk-container-expand">
      <div class="uk-section uk-section-xsmall uk-margin-xlarge-bottom">
        <div class="uk-grid" data-uk-grid v-if="$can('manage', 'customers')">
          <div class="uk-width-1-1">
            <div class="uk-card uk-card-default uk-card-small">
              <div class="uk-card-header">
                <div class="uk-grid uk-grid-small uk-text-small">
                  <div class="uk-width-expand">
                    <h4 class="uk-margin-remove">Allgemein</h4>
                  </div>
                  <div class="uk-width-auto uk-text-right uk-text-muted">
                    <button class="uk-button uk-button-link" type="button" uk-toggle="target: #toggle-card">
                      <span data-uk-icon="chevron-down"></span>
                    </button>
                  </div>
                </div>
              </div>
              <div class="uk-card-body" id="toggle-card" :hidden="form.id">
                <div class="uk-grid" data-uk-grid>
                  <ValidationObserver ref="observer">
                    <div class="uk-width-expand">
                      <div class="uk-grid uk-grid-small" data-uk-grid>
                        <div class="uk-width-1-3@xl uk-width-1-2@l">
                          <ValidationProvider rules="required" name="customer" v-slot="{ errors }">
                            <CustomerSelect v-model="form.details" label="Rechnungsempfänger*" type="customer"
                              @getSelected="onCustomerGetSelected" :errors="errors[0]" />
                          </ValidationProvider>
                        </div>
                        <div class="uk-width-1-3@xl uk-width-1-2@l">
                          <label class="uk-form-label">Projekt-Nr.</label>
                          <div class="uk-form-controls">
                            <input class="uk-input uk-border-rounded" v-model="form.number" placeholder="Automatisch"
                              disabled />
                          </div>
                        </div>
                        <div class="uk-width-1-3@xl uk-width-1-2@l">
                          <label class="uk-form-label">Auftragsdatum</label>
                          <div class="uk-form-controls">
                            <date-picker mode="date" v-model="date" :attributes="datepickerAttr"
                              :popover="{ visibility: 'click' }">
                              <template v-slot="{ inputValue, inputEvents }">
                                <input class="uk-input" :value="inputValue" v-on="inputEvents" />
                              </template>
                            </date-picker>
                          </div>
                        </div>
                        <div class="uk-width-1-3@xl uk-width-1-2@l">
                          <ValidationProvider rules="required" name="technician" v-slot="{ errors }">
                            <CustomerSelect v-model="form.technician" label="Messtechniker*" type="technician"
                              @getSelected="onCustomerGetSelected" :errors="errors[0]" />
                          </ValidationProvider>
                        </div>
                        <!--
                        <div class="uk-width-1-3@xl uk-width-1-2@l">
                          <label class="uk-form-label">Vers.-Nr.</label>
                          <div class="uk-form-controls">
                            <input class="uk-input uk-border-rounded" v-model="form.insuranceNumber" />
                          </div>
                        </div>
                        <div class="uk-width-1-3@xl uk-width-1-2@l">
                          <label class="uk-form-label">Schaden-Nr.</label>
                          <div class="uk-form-controls">
                            <input class="uk-input uk-border-rounded" v-model="form.damageNumber" />
                          </div>
                        </div>
                      -->
                        <div class="uk-width-1-3@xl uk-width-1-2@l">
                          <ValidationProvider rules="required" name="insuranceContact" v-slot="{ errors }">
                            <CustomerSelect key="insuranceContact" v-model="form.insuranceContact"
                              label="Versicherung *" type="insuranceContact" :errors="errors[0]" />
                          </ValidationProvider>
                        </div>
                        <div class="uk-width-1-3@xl uk-width-1-2@l">
                          <label class="uk-form-label">Abtretung vorhanden?</label>
                          <div class="uk-margin-small uk-grid-small uk-child-width-auto uk-grid">
                            <label><input class="uk-checkbox" v-model="form.insurance.abtretung" type="checkbox"
                                value="ja" /> ja</label>
                            <label><input class="uk-checkbox" v-model="form.insurance.abtretung" type="checkbox"
                                value="nein" /> nein</label>
                            <label><input class="uk-checkbox" v-model="form.insurance.abtretung" type="checkbox"
                                value="folgt" /> folgt</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ValidationObserver>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="uk-grid" data-uk-grid>
          <div class="uk-width-1-1">
            <div data-uk-sticky="offset:113;top:0;media:@m" class="sticky-tabs" style="z-index: 2 !important">
              <ul data-uk-tab="connect: .uk-switcher;swiping: false">
                <li><a href="#">Kontaktdaten</a></li>
                <li><a href="#">Leckageortung</a></li>
                <li v-if="form.id"><a href="#">Bilder</a></li>
                <li v-if="form.id"><a href="#">Messbericht</a></li>
                <li v-if="$can('edit', 'project:power-consumption') && form.id"><a href="#">Stromverbrauch</a></li>
                <li v-if="form.id"><a href="#">Schadenaufnahme</a></li>
                <li v-if="form.id"><a href="#">Geräte</a></li>
              </ul>
            </div>
          </div>
          <div class="uk-width-1-1">
            <ul class="uk-switcher uk-margin">
              <li>
                <div class="uk-grid" data-uk-grid>
                  <div class="uk-width-1-4@l">
                    <h4 class="uk-margin-small">Baustelle</h4>
                  </div>
                  <div class="uk-width-expand">
                    <div class="uk-card uk-card-default">
                      <div class="uk-card-body">
                        <customer-data :customer="form.details"></customer-data>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="uk-grid" data-uk-grid>
                  <div class="uk-width-1-4@l">
                    <h4 class="uk-margin-small">Arbeitszeit vor Ort</h4>
                    <div class="uk-text-muted uk-text-small">Arbeitszeiten</div>
                  </div>
                  <div class="uk-width-expand">
                    <div class="uk-card uk-card-default">
                      <div class="uk-card-body">
                        <working-time v-model="form.workingTime"></working-time>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="uk-grid uk-margin-large-top" data-uk-grid>
                  <div class="uk-width-1-4@l">
                    <h4 class="uk-margin-small">Messbericht</h4>
                    <div class="uk-text-muted uk-text-small">Messbericht</div>
                  </div>
                  <div class="uk-width-expand">
                    <div class="uk-card uk-card-default">
                      <div class="uk-card-body">
                        <measurement-report v-model="form.measurementReport"></measurement-report>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="uk-grid uk-margin-large-top" data-uk-grid>
                  <div class="uk-width-1-4@l">
                    <h4 class="uk-margin-small">Eingesetzte Mess-/Arbeitsgeräte</h4>
                    <div class="uk-text-muted uk-text-small">Mess- und Arbeitsgeräte</div>
                  </div>
                  <div class="uk-width-expand">
                    <div class="uk-card uk-card-default">
                      <div class="uk-card-body">
                        <measurement-tools v-model="form.measurementTools"></measurement-tools>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="uk-grid" data-uk-grid>
                  <div class="uk-width-1-1@l">
                    <button class="uk-button uk-button-primary uk-button-small" @click.prevent="addImage">
                      <span data-uk-icon="plus-circle"></span>
                      Bild hinzufügen
                    </button>
                  </div>
                  <template v-if="form.images.length">
                    <div class="uk-width-1-3@m" v-for="(image, i) in form.images" :key="i">
                      <div class="uk-margin">
                        <div class="uk-card uk-card-default uk-margin-medium-bottom">
                          <div class="uk-card-body">
                            <div class="uk-width-1-1">
                              <select-image :source="image.path" :project-number="form.number" @image-selected="onImageSelected(i, $event)"
                                @image-removed="onImageRemoved(i, $event)" :direct-upload="true" />
                              <div class="uk-margin">
                                <label class="uk-form-label">Ort der Aufnahme</label>
                                <div class="uk-form-controls">
                                  <input class="uk-input uk-border-rounded" v-model="image.location" />
                                </div>
                              </div>
                              <div class="uk-margin">
                                <label class="uk-form-label">Dokumentation</label>
                                <div class="uk-form-controls">
                                  <textarea rows="5" class="uk-textarea uk-border-rounded"
                                    v-model="image.description"></textarea>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="uk-card-footer">
                            <div class="uk-text-right">
                              <button class="uk-button uk-button-link uk-text-danger uk-margin-right"
                                @click.prevent="deleteImage(image.id)">Löschen</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </li>
              <li v-if="form.id">
                <div class="uk-grid" data-uk-grid>
                   <div class="uk-width-1-1">
                    <div class="uk-card uk-card-default uk-card-body">
                      <upload
                        :url="finderUploadUrl"
                        @uploaded="onFinderImageUploaded"
                        :multiple="true"
                        :path="`/${form.number}`"
                        root="/files"
                        :dropzone="true"
                      ></upload>
                      <button type="button" class="uk-button uk-button-secondary uk-button-small" @click.prevent="downloadFinderZip" :disabled="downloadingFinderZip" v-if="finderImages.length">
                        <span data-uk-icon="icon:cloud-download;ratio:0.8"></span>
                        Download Zip
                      </button>
                    </div>
                  </div>
                  <div class="uk-width-1-1">
                    <ImageThumbnails :searched="finderImages" @selected="onSelectedFinderImages" :hide-folders="true"></ImageThumbnails>
                  </div>
                  <div class="uk-width-1-1">
                    <div class="uk-flex uk-flex-center">
                      <button type="button" class="uk-button uk-button-danger" @click.prevent="deleteFinderImages" :disabled="deletingFinderImages" v-if="selectedFinderImages.length">
                          <span data-uk-icon="icon:trash;ratio:0.8"></span>
                          Ausgewählte Löschen
                      </button>
                    </div>
                  </div>
                </div>
              </li>
              <li v-if="form.id">
                <div class="uk-grid" data-uk-grid>
                  <div class="uk-width-1-4@l">
                    <h4 class="uk-margin-small">Messgeräte</h4>
                    <div class="uk-text-muted uk-text-small">Messgeräte</div>
                  </div>
                  <div class="uk-width-expand">
                    <div class="uk-card uk-card-default">
                      <div class="uk-card-body">
                        <measurement-complete-tools v-model="form.measurementCompleteTools">
                        </measurement-complete-tools>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="uk-grid" data-uk-grid>
                  <div class="uk-width-1-4@l">
                    <h4 class="uk-margin-small">Trocknung</h4>
                    <div class="uk-text-muted uk-text-small">Trocknung</div>
                  </div>
                  <div class="uk-width-expand">
                    <div class="uk-card uk-card-default">
                      <div class="uk-card-body">
                        <measurement-complete-drying v-model="form.measurementCompleteDrying">
                        </measurement-complete-drying>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="uk-grid" data-uk-grid>
                  <div class="uk-width-1-4@l">
                    <h4 class="uk-margin-small">Räume</h4>
                    <button class="uk-button uk-button-primary uk-button-small" @click.prevent="addRoom">
                      <span data-uk-icon="plus-circle"></span>
                      Raum hinzufügen
                    </button>
                  </div>
                  <div class="uk-width-expand">
                    <div class="uk-margin-medium" v-for="(room, i) in form.measurementCompleteRooms" :key="i">
                      <div class="uk-card uk-card-default">
                        <div class="uk-card-body">
                          <measurement-complete-room v-model="form.measurementCompleteRooms[i]" :upload-url="uploadUrl"
                            :room-index="i" @imageUploaded="onImageUpload"></measurement-complete-room>
                        </div>
                        <div class="uk-card-footer">
                          <div class="uk-text-right">
                            <button class="uk-button uk-button-link uk-text-danger uk-margin-right"
                              @click.prevent="deleteRoom(i)">Raum entfernen</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <!--
              <li v-if="$can('edit', 'project:damage-recording') && form.id">
                <damage-recording v-model="form.damageRecording" :upload-url="uploadUrl" @imageUploaded="onImageUpload"
                  :project-id="form.id"></damage-recording>
              </li>
              <li v-if="$can('edit', 'project:insurance') && form.id">
                <insurance v-model="form.insurance"></insurance>
              </li>
              -->
              <li v-if="$can('edit', 'project:power-consumption') && form.id">
                <div class="uk-grid" data-uk-grid>
                  <div class="uk-width-1-4@l">
                    <h4 class="uk-margin-small">Stromverbrauch</h4>
                    <button class="uk-button uk-button-primary uk-button-small" @click.prevent="addPower">
                      <span data-uk-icon="plus-circle"></span>
                      Stromverbrauch hinzufügen
                    </button>
                  </div>
                  <div class="uk-width-expand">
                    <div class="uk-margin-medium" v-for="(powerConsumption, i) in form.powerConsumption" :key="i">
                      <div class="uk-card uk-card-default">
                        <div class="uk-card-body">
                          <power-consumption v-model="form.powerConsumption[i]"></power-consumption>
                        </div>
                        <div class="uk-card-footer">
                          <div class="uk-text-right">
                            <button class="uk-button uk-button-link uk-text-danger uk-margin-right"
                              @click.prevent="deletePowerConsumption(i)">Stromverbrauch entfernen</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li v-if="form.id">
                <div class="uk-grid" data-uk-grid>
                  <div class="uk-width-1-1">
                    <div class="uk-card uk-card-default uk-card-body">
                    <upload
                        :url="finderUploadUrl"
                        @uploaded="onFinderDamageReportUploaded"
                        :multiple="true"
                        :path="`/${form.number}/schadenaufnahme`"
                        root="/files"
                        :dropzone="true"
                      ></upload>
                      </div>
                  </div>
                  <div class="uk-width-1-1" v-if="finderDamageReports.length">
                    <div class="uk-margin" v-for="(damageReport,i) in finderDamageReports" :key="i">
                      <iframe :src="damageReport.url" width="100%" height="800px"></iframe>
                      <div class="uk-margin-top uk-flex uk-flex-center">
                        <button type="button" class="uk-button uk-button-danger" @click.prevent="deleteDamageReportFile(damageReport.name)">
                          <span data-uk-icon="icon:trash;ratio:0.8"></span>
                          Löschen
                      </button>
                      </div>
                    </div>
                  </div>
                  <!--
                  <div class="uk-width-1-1@l">
                    <select-document @document-selected="onDocumentSelected" />
                  </div>
                  <template v-if="form.documents.length">
                    <div class="uk-width-1-1" v-for="(documents, type) in groupedDocuments" :key="type">
                      <div class="uk-h4 uk-margin-small">{{ type }}</div>
                      <div class="uk-card uk-card-default">
                        <div class="uk-card-body uk-padding-remove">
                          <div class="uk-overflow-auto">
                            <table
                              class="uk-table uk-table-divider uk-table-small uk-table-middle uk-table-striped uk-table-hover has-checkbox">
                              <thead>
                                <tr>
                                  <th class="uk-width-3-4@m">Datei</th>
                                  <th class="uk-table-small">Datum</th>
                                  <th class="uk-table-small"></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(document, i) in documents" :key="i">
                                  <td class="uk-text-nowrap">
                                    {{ document.name }}
                                  </td>
                                  <td>{{ document.added }}</td>
                                  <td class="uk-text-nowrap uk-text-right">
                                    <a v-if="document.id" href="#" @click.prevent="showDocumentPreview(document)"
                                      class="uk-link-reset" data-uk-tooltip="Ansicht">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                                        fill="currentColor">
                                        <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                                        <path fill-rule="evenodd"
                                          d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                                          clip-rule="evenodd" />
                                      </svg>
                                    </a>
                                    &nbsp;
                                    <a v-if="document.id" :href="document.path" target="_blank" class="uk-link-reset"
                                      data-uk-tooltip="Download">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="20" heigh="20" viewBox="0 0 20 20"
                                        fill="currentColor">
                                        <path fill-rule="evenodd"
                                          d="M2 9.5A3.5 3.5 0 005.5 13H9v2.586l-1.293-1.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 15.586V13h2.5a4.5 4.5 0 10-.616-8.958 4.002 4.002 0 10-7.753 1.977A3.5 3.5 0 002 9.5zm9 3.5H9V8a1 1 0 012 0v5z"
                                          clip-rule="evenodd" />
                                      </svg>
                                    </a>
                                    &nbsp;
                                    <a v-if="document.id" class="uk-text-danger uk-dropdown-close" href="#"
                                      @click.prevent="deleteDocument(document.id)"
                                      data-uk-tooltip="Aus Projekt entfernen">
                                      <span class="uk-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                          viewBox="0 0 20 20" fill="currentColor">
                                          <path fill-rule="evenodd"
                                            d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                            clip-rule="evenodd" />
                                        </svg>
                                      </span>
                                    </a>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                -->
                </div>
              </li>
              <li v-if="form.id">
                <div class="uk-grid" data-uk-grid>
                  <div class="uk-width-1-4@l">
                    <h4 class="uk-margin-small">Geräte</h4>
                    <button class="uk-button uk-button-primary uk-button-small" @click.prevent="addDevice">
                      <span data-uk-icon="plus-circle"></span>
                      Gerät hinzufügen
                    </button>
                  </div>
                  <div class="uk-width-expand">
                    <div class="uk-margin-medium" v-for="(device, i) in form.devices" :key="i">
                      <div class="uk-card uk-card-default">
                        <div class="uk-card-body">
                          <device v-model="form.devices[i]"></device>
                        </div>
                        <div class="uk-card-footer">
                          <div class="uk-text-right">
                            <button class="uk-button uk-button-link uk-text-danger uk-margin-right"
                              @click.prevent="deleteDevice(i)">Gerät entfernen</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="uk-hidden@m uk-inline">
      <div style="height: 50px"></div>
      <div class="uk-position-bottom uk-position-fixed uk-padding-small uk-text-center"
        style="z-index: 10; border-top: 1px solid #d6d4d4; background: #f7f7f7; padding-bottom: 25px">
        <div uk-margin>
          <button v-if="form.id" uk-toggle="target: #print" type="button"
            class="uk-button uk-button-secondary uk-border-rounded uk-margin-small-right">
            <span data-uk-icon="print"></span>
            Drucken
          </button>
          <button class="uk-button uk-button-primary uk-border-rounded" type="submit" :disabled="sending"
            @click.prevent="handleSubmit(false)">
            <span class="uk-icon" style="width: 20px">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path
                  d="M7.707 10.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V6h5a2 2 0 012 2v7a2 2 0 01-2 2H4a2 2 0 01-2-2V8a2 2 0 012-2h5v5.586l-1.293-1.293zM9 4a1 1 0 012 0v2H9V4z" />
              </svg>
            </span>
            Speichern
          </button>
        </div>
        <a href="#" @click.prevent="$router.go(-1)" class="uk-button uk-button-text uk-margin-small-top">Schließen</a>
      </div>
    </div>

    <div id="print" data-uk-modal>
      <div class="uk-modal-dialog uk-modal-body">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <h3>Drucken</h3>
        <div data-uk-margin>
          <button class="uk-button uk-width-1-1 uk-button-secondary uk-border-rounded" type="submit"
            :disabled="printing" @click.prevent="print">
            <span data-uk-icon="print"></span>
            Abtretungserklärung Drucken
          </button>
          <button class="uk-button uk-button-secondary uk-border-rounded uk-width-1-1" type="submit"
            :disabled="printingLeckage" @click.prevent="printLeckage">
            <span data-uk-icon="print"></span>
            Leckagebericht Drucken
          </button>
          <button class="uk-button uk-button-secondary uk-border-rounded uk-width-1-1" type="submit"
            :disabled="printingMeasurment" @click.prevent="printMeasurment">
            <span data-uk-icon="print"></span>
            Messbericht Drucken
          </button>
          <button class="uk-button uk-button-secondary uk-border-rounded uk-width-1-1" type="submit"
            :disabled="printingPowerConsumption" @click.prevent="printPowerConsumption">
            <span data-uk-icon="print"></span>
            Stromverbrauch Drucken
          </button>
          <!--
          <button class="uk-button uk-button-secondary uk-border-rounded uk-width-1-1" type="submit"
            :disabled="printingInsurance" @click.prevent="printInsurance">
            <span data-uk-icon="print"></span>
            Versicherungsunterlagen Drucken
          </button>
        -->
        </div>
      </div>
    </div>
    <mail-modal ref="mailModal" :document-id="form.id" :document-type="mailDocument" :subject="mailSubject"
      :mail-receivers="mailReceivers" :send-type="'email'" :message="'completeMessage'">
    </mail-modal>
  </div>
</template>

<script>
import ProjectService from '@/services/project.service'
import ImageService from '@/services/image.service'
import 'izitoast/dist/css/iziToast.min.css'
import iziToast from 'izitoast/dist/js/iziToast.min.js'
import CustomerSelect from '@/components/CustomerSelect'
import CustomerData from '@/components/CustomerData'
import MeasurementReport from '@/components/protocol/MeasurementReport'
import MeasurementCompleteTools from '@/components/protocol/MeasurementCompleteTools'
import MeasurementCompleteDrying from '@/components/protocol/MeasurementCompleteDrying'
import MeasurementCompleteRoom from '@/components/protocol/MeasurementCompleteRoom'
import MeasurementTools from '@/components/protocol/MeasurementTools'
import WorkingTime from '@/components/protocol/WorkingTime'
//import DamageRecording from '@/components/protocol/DamageRecording'
//import Insurance from '@/components/protocol/Insurance'
import moment from 'moment'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import localforage from 'localforage'
import Configuration from '@/configuration'
import MailModal from '@/components/MailModal'
import SelectImage from '@/components/finder/SelectImage'
import Device from '@/components/protocol/Device'
//import SelectDocument from '@/components/finder/SelectDocument'
import Upload from '@/components/finder/Upload'
const baseURL = Configuration.value('apiUrl')
import { mapActions } from 'vuex'
import ImageThumbnails from '@/components/finder/views/Thumbnail.vue'


extend('required', {
  ...required,
  message: 'Pflichtfeld',
})

import axios from 'axios'
import PowerConsumption from '@/components/protocol/PowerConsumption.vue'
import documentService from '../../../services/document.service'
export default {
  data() {
    return {
      sending: false,
      printing: false,
      printingMeasurment: false,
      printingLeckage: false,
      printingPowerConsumption: false,
      printingInsurance: false,
      form: {
        id: null,
        number: null,
        date: moment(new Date()).format('YYYY-MM-DD'),
        presents: [],
        presents_other: '',
        insuranceNumber: '',
        damageNumber: '',
        details: {
          customerNumber: null,
          company: '',
          salutation: 'Herr',
          firstname: '',
          lastname: '',
          asp: '',
          street: '',
          zipcode: '',
          city: '',
          email: '',
          phone: '',
          fax: '',
          mobile: '',
        },
        technician: null,
        measurements: [],
        images: [],
        documents: [],
        measurementReport: {},
        measurementTools: {},
        measurementCompleteTools: {},
        measurementCompleteDrying: {},
        measurementCompleteRooms: [],
        damageRecording: {},
        insurance: {
          abtretung: [],
        },
        powerConsumption: [],
        devices: [],
      },
      datepickerAttr: [
        {
          dot: true,
          dates: new Date(),
        },
      ],
      mailDocument: null,
      mailSubject: '',
      mailReceivers: [],
      documentPreview: null,
      finderImages: [],
      selectedFinderImages: [],
      finderDamageReports: [],
      downloadingFinderZip: false,
      deletingFinderImages: false,
    }
  },
  mounted() {
    if (typeof this.$route.params.id !== 'undefined') {
      this.getProject(this.$route.params.id)
    }
  },
  components: {
    CustomerSelect,
    CustomerData,
    DatePicker,
    SelectImage,
    MeasurementReport,
    MeasurementCompleteTools,
    MeasurementCompleteDrying,
    MeasurementCompleteRoom,
    MeasurementTools,
    WorkingTime,
    ValidationProvider,
    ValidationObserver,
    //DamageRecording,
    //Insurance,
    PowerConsumption,
    MailModal,
    Device,
    Upload,
    ImageThumbnails,
    //SelectDocument,
  },
  computed: {
    headerLabel() {
      let label = 'Projekt erstellen'
      if (this.form.id) {
        label = `${this.form.number} ${this.form.details.lastname} ${this.form.details.city}`
      }
      return label
    },
    uploadUrl() {
      return Configuration.value('apiUrl') + '/image/' + this.form.id
    },
    finderUploadUrl() {
      return `${Configuration.value('apiUrl')}/finder/upload`
    }, 
    date: {
      get() {
        return this.form.date ? new Date(this.form.date) : new Date()
      },
      set(value) {
        this.form.date = moment(value).format('YYYY-MM-DD')
      },
    },
    groupedDocuments() {
      return this.groupByKey(this.form.documents, 'typeName')
    },
  },
  methods: {
    ...mapActions({
      getFiles: 'media/getFiles',
      removeFiles: 'media/removeFiles',
    }),
    getProject(id) {
      ProjectService.get(id).then((response) => {
        this.form = response.data
        this.onFinderImageUploaded()
        this.onFinderDamageReportUploaded()
      })
    },
    async handleSubmit(close) {
      const observer = this.$refs.observer
      if (observer !== undefined) {
        const success = await observer.validate()

        if (!success) {
          for (const key of Object.keys(observer.fields)) {
            if (observer.fields[key].invalid) {
              window.scroll({
                top: observer.refs[key].$el.offsetTop - 80,
                left: 0,
                behavior: 'smooth',
              })
              return
            }
          }
        }
      }

      this.store(close)
    },
    async store(close) {
      this.sending = true
      let response = null
      if (this.form.id) {
        try {
          response = await ProjectService.update(this.form.id, this.form)
          iziToast.success({
            title: 'OK',
            message: 'Projekt gespeichert!',
            timeout: 3000,
            position: 'bottomRight',
            transitionIn: 'fadeInLeft',
          })
        } catch (error) {
          iziToast.error({
            title: 'Fehler',
            message: 'Es ist ein Fehler aufgetreten!',
            timeout: 3000,
            position: 'bottomRight',
            transitionIn: 'fadeInLeft',
          })
        }
      } else {
        try {
          response = await ProjectService.store(this.form)
          iziToast.success({
            title: 'OK',
            message: 'Projekt gespeichert!',
            timeout: 3000,
            position: 'bottomRight',
            transitionIn: 'fadeInLeft',
          })
        } catch (error) {
          iziToast.error({
            title: 'Fehler',
            message: 'Es ist ein Fehler aufgetreten!',
            timeout: 3000,
            position: 'bottomRight',
            transitionIn: 'fadeInLeft',
          })
          console.log(error)
        }
      }

      if (response) {
        this.getProject(response.data.id)
      }

      this.sending = false

      if (close) {
        this.$router.push({
          name: 'project',
        })
      }
    },
    onCreateNewCustomer() {
      //open Modal
    },
    onCustomerGetSelected() {
      //
    },
    onImageUpload() {
      this.getProject(this.form.id)
    },
    addImage() {
      this.form.images.unshift({
        id: null,
        path: '',
        ref: '',
        location: '',
        description: '',
      })
    },
    onImageSelected(index, image) {
      this.form.images[index].ref = image.ref
      this.form.images[index].path = image.url
    },
    onImageRemoved(index) {
      this.form.images[index].ref = ''
      this.form.images[index].path = ''
    },
    deleteImage(id) {
      const $this = this
      this.$uikit.modal
        .confirm('Bild löschen?', {
          labels: {
            cancel: 'Abbrechen',
            ok: 'Löschen',
          },
        })
        .then(
          function () {
            ImageService.delete(id).then(() => {
              iziToast.success({
                title: 'OK',
                message: 'Projekt gespeichert!',
                timeout: 3000,
                position: 'bottomRight',
                transitionIn: 'fadeInLeft',
              })

              $this.getProject($this.form.id)
            })
          },
          function () {
            console.log('Rejected.')
          }
        )
    },
    addRoom() {
      this.form.measurementCompleteRooms.push({
        name: '',
        digits: false,
        absoluteWerte: false,
        decke: false,
        boden: false,
        wand: false,
        raum: false,
        table: [
          {
            date: new Date(),
            grad: '18',
            prozent: '60',
            mp1: '',
            mp2: '',
            mp3: '',
            mp4: '',
            mp5: '',
            mp6: '',
            mp7: '',
            mp8: '',
          },
        ],
      })
    },
    addPower() {
      this.form.powerConsumption.push({
        salutation: '',
        name: '',
        street: '',
        zipcode: '',
        city: '',
        email: '',
        dateFrom: new Date(),
        dateTo: new Date(),
        power: '',
      })
    },
    addDevice() {
      this.form.devices.push({
        name: '',
        serial: '',
        description: '',
      })
    },
    async saveSignature() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature()
      if (!isEmpty) {
        await ProjectService.storeSignature(this.form.id, { data })
        iziToast.success({
          title: 'OK',
          message: 'Unterschrift gespeichert!',
          timeout: 3000,
          position: 'bottomRight',
          transitionIn: 'fadeInLeft',
        })
        this.getProject(this.form.id)
      }
    },
    undoSignature() {
      this.$refs.signaturePad.clearSignature()
    },
    print() {
      this.printing = true
      localforage.getItem('authtoken').then((token) => {
        axios({
          url: `${baseURL}/print/${this.form.id}`,
          method: 'POST',
          headers: { common: { Authorization: token } },
          responseType: 'blob', // important
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          //const filename = response.headers["content-disposition"].split('filename=')[1].split(';')[0];

          link.href = url
          link.setAttribute('download', `Gutachen ${this.form.number} ${this.form.details.city}.pdf`)
          document.body.appendChild(link)
          link.click()
          this.printing = false
        })
      })
    },
    printMeasurment() {
      this.printingMeasurment = true
      localforage.getItem('authtoken').then((token) => {
        axios({
          url: `${baseURL}/print/measurement/${this.form.id}`,
          headers: { common: { Authorization: token } },
          method: 'POST',
          responseType: 'blob', // important
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          //const filename = response.headers["content-disposition"].split('filename=')[1].split(';')[0];

          link.href = url
          link.setAttribute('download', `Messbericht ${this.form.number} ${this.form.details.city}.pdf`)
          document.body.appendChild(link)
          link.click()
          this.printingMeasurment = false
        })
      })
    },
    printLeckage() {
      this.printingLeckage= true
      localforage.getItem('authtoken').then((token) => {
        axios({
          url: `${baseURL}/print/leckage/${this.form.id}`,
          headers: { common: { Authorization: token } },
          method: 'POST',
          responseType: 'blob', // important
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          //const filename = response.headers["content-disposition"].split('filename=')[1].split(';')[0];

          link.href = url
          link.setAttribute('download', `Leckagebericht ${this.form.number} ${this.form.details.city}.pdf`)
          document.body.appendChild(link)
          link.click()
          this.printingLeckage = false
        })
      })
    },
    printPowerConsumption() {
      this.printingPowerConsumption = true
      localforage.getItem('authtoken').then((token) => {
        axios({
          url: `${baseURL}/print/power-consumption/${this.form.id}`,
          headers: { common: { Authorization: token } },
          method: 'POST',
          responseType: 'blob', // important
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          //const filename = response.headers["content-disposition"].split('filename=')[1].split(';')[0];

          link.href = url
          link.setAttribute('download', `Stromverbrauch ${this.form.number} ${this.form.details.city}.pdf`)
          document.body.appendChild(link)
          link.click()
          this.printingPowerConsumption = false
        })
      })
    },
    printInsurance() {
      this.printingInsurance = true
      localforage.getItem('authtoken').then((token) => {
        axios({
          url: `${baseURL}/print/insurance/${this.form.id}`,
          headers: { common: { Authorization: token } },
          method: 'POST',
          responseType: 'blob', // important
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          //const filename = response.headers["content-disposition"].split('filename=')[1].split(';')[0];

          link.href = url
          link.setAttribute('download', `Versicherungsunterlagen ${this.form.number} ${this.form.details.city}.pdf`)
          document.body.appendChild(link)
          link.click()
          this.printingInsurance = false
        })
      })
    },
    resizeSignature() {
      setTimeout(() => {
        if (typeof this.$refs.signaturePad !== 'undefined') {
          this.$refs.signaturePad.resizeCanvas()
        }
      }, 250)
    },
    deleteRoom(index) {
      this.form.measurementCompleteRooms.splice(index, 1)
    },
    deletePowerConsumption(index) {
      this.form.powerConsumption.splice(index, 1)
    },
    onInsuranceImageStored() {
      iziToast.success({
        title: 'OK',
        message: 'Bild gespeichert!',
        timeout: 3000,
        position: 'bottomRight',
        transitionIn: 'fadeInLeft',
      })
    },
    sendMail(document) {
      this.mailDocument = document

      let documentLabel = ''
      if (document === 'power-consumption') {
        documentLabel = 'Stromverbrauch'
        this.mailReceivers = this.form.powerConsumption.map((p) => {
          return { name: p.name, email: p.email }
        })
      }

      if (document === 'insurance') {
        documentLabel = 'Versicherungsunterlagen'
        const customerName = this.form.details.company || `${this.form.details.firstname} ${this.form.details.lastname}`

        this.mailReceivers = [
          { name: customerName, email: this.form.details.email, type: 'Kunde' },
          { name: this.form.insuranceContact.name, email: this.form.insuranceContact.email, type: 'Versicherung' },
        ]
      }

      this.mailSubject = `${documentLabel} ${this.form.number} - ${this.form.details.zipcode} ${this.form.details.city}`
      this.$refs.mailModal.open()
    },
    async createOffer() {
      const response = await ProjectService.createOffer(this.form.id)
      setTimeout(() => {
        this.$router.push({
          name: 'offer-edit',
          params: { id: response.data.id },
        })
      }, 500)
    },
    onDocumentSelected(selected) {
      selected.files.forEach((f) => {
        this.form.documents.unshift({
          id: null,
          ref: f.ref,
          name: f.name,
          type: selected.type,
        })
      })
      setTimeout(() => {
        this.store()
      }, 500)
    },
    deleteDocument(id) {
      const $this = this
      this.$uikit.modal
        .confirm('Dokument aus Projekt entfernen?', {
          labels: {
            cancel: 'Abbrechen',
            ok: 'Entfernen',
          },
        })
        .then(
          function () {
            documentService.delete(id).then(() => {
              iziToast.success({
                title: 'OK',
                message: 'Dokument von Projekt entfernt!',
                timeout: 3000,
                position: 'bottomRight',
                transitionIn: 'fadeInLeft',
              })

              $this.getProject($this.form.id)
            })
          },
          function () {
            console.log('Rejected.')
          }
        )
    },
    showDocumentPreview(doc) {
      this.documentPreview = doc.path
      this.$uikit.modal(this.$refs.documentPreviewModal).show()
    },
    async onFinderImageUploaded() {
      try {
        const response = await this.getFiles({ root: 'files', path: '/' + this.form.number })
        this.finderImages = response.data.items || []
        //this.selected = []
      } catch (error) {
        iziToast.error({
          title: 'Fehler',
          message: 'Unable to access directory',
          timeout: 3000,
          position: 'topRight',
          transitionIn: 'fadeInLeft',
        })
      }
    },
    async onFinderDamageReportUploaded() {
      try {
        const response = await this.getFiles({ root: 'files', path: '/' + this.form.number + '/schadenaufnahme' })
        this.finderDamageReports = response.data.items || []
      } catch (error) {
      }
    },
    onSelectedFinderImages(selected) {
      this.selectedFinderImages = selected
    },
    downloadFinderZip() {
      this.downloadingFinderZip = true
      localforage.getItem('authtoken').then((token) => {
        axios({
          url: `${baseURL}/finder/download`,
          data: { path: '/', root: 'files', names: [this.form.number] },
          method: 'POST',
          headers: { common: { Authorization: token } },
          responseType: 'blob', // important
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          const filename = response.headers["content-disposition"].split('filename=')[1].split(';')[0];

          link.href = url
          link.setAttribute('download', filename)
          document.body.appendChild(link)
          link.click()
          this.downloadingFinderZip = false
        })
      })
    },
    deleteFinderImages() {
      this.removeFiles({ path: '/' + this.form.number, root: 'files', names: this.selectedFinderImages }).then((response) => {
          this.onFinderImageUploaded()
          this.selectedFinderImages = []
          iziToast.success({
            title: 'Erfolgreich',
            message: response.data.message,
            timeout: 3000,
            position: 'topRight',
            transitionIn: 'fadeInLeft',
          })
        })
    },
    deleteDamageReportFile(file) {
      this.removeFiles({ path: '/' + this.form.number + '/schadenaufnahme', root: 'files', names: [file] }).then((response) => {
          this.onFinderDamageReportUploaded()
          iziToast.success({
            title: 'Erfolgreich',
            message: response.data.message,
            timeout: 3000,
            position: 'topRight',
            transitionIn: 'fadeInLeft',
          })
        })
    },
    groupByKey(array, key) {
      return array.reduce((hash, obj) => {
        if (obj[key] === undefined) return hash
        return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) })
      }, {})
    },
  },
}
</script>
