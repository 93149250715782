import Api from './api.service'

export default {
  getByProject (projectId) {
    return Api().get(`image/${projectId}`)
  },
  delete (id) {
    return Api().delete(`image/${id}`)
  }
}
