<template>
  <div class="uk-modal uk-modal-container" ref="modal">
    <div class="uk-modal-dialog">
      <div class="uk-modal-header">
        <h3 class="uk-modal-title">{{ identifierLabel }} verschicken</h3>
      </div>
      <div class="uk-modal-body">
        <div class="uk-margin">
          <label class="uk-form-label">Empfänger</label><br />
          <template v-if="mailReceivers.length > 0">
            <div class="uk-flex" v-for="(receiver,i ) in mailReceivers" :key="i">
              <template v-if="receiver.type">
                {{ receiver.type }}: &nbsp;
              </template>
              <p class="uk-margin-remove">
                {{ receiver.name }} <span v-if="!receiver.email">(Bitte E-Mail Adresse in den Kundendaten hinterlegen)</span>
                <span v-else>({{ receiver.email }})</span>
              </p>
            </div>
          </template>
          <p v-else>Bitte E-Mail Adresse in den Kundendaten hinterlegen</p>
        </div>
        <div class="uk-margin">
          <label class="uk-form-label">Betreff</label>
          <input type="text" class="uk-input" v-model="form.subject" />
        </div>
        <div class="uk-margin">
          <label class="uk-form-label">Nachricht</label>
          <textarea type="text" class="uk-textarea" v-model="form.message" rows="10"></textarea>
        </div>
      </div>
      <div class="uk-modal-footer">
        <div class="uk-text-right">
          <button class="uk-button uk-button-primary uk-button-small uk-border-rounded" @click.prevent="send" :disabled="submit">
            <span data-uk-spinner="ratio: 0.6" v-if="submit"></span>
            <span v-else>Senden</span>
          </button>
          <button class="uk-button uk-button-text uk-modal-close uk-margin-small-left" type="button">Abbrechen</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProjectService from '@/services/project.service'
import ConfigService from '@/services/config.service'
import 'izitoast/dist/css/iziToast.min.css'
import iziToast from 'izitoast/dist/js/iziToast.min.js'

export default {
  props: {
    documentId: {
      required: true,
    },
    documentType: {
      required: true,
    },
    mailReceivers: {
      required: true,
      type: Array,
    },
    subject: {
      required: true,
    },
    sendType: {
      required: false,
      type: String,
    },
    hideSelect: {
      required: false,
      default: false,
    },
  },
  data() {
    return {
      submit: false,
      config: {},
      form: {
        email: this.email || '',
        subject: this.subject || '',
        message: this.message || '',
      },
    }
  },
  mounted() {
    if (this.sendType) {
      this.selected = this.sendType
    }
  },
  watch: {
    email(val) {
      this.form.email = val
    },
    message(val) {
      this.form.message = val
    },
    subject(val) {
      this.form.subject = val
    },
  },
  computed: {
    identifier() {
      return this.documentType
    },
    identifierLabel() {
      return this.documentType === 'power-consumption' ? 'Stromverbrauch' : 'Versicherungsunterlagen'
    },
    requestType() {
      return this.documentType
    },
    message() {
      if (this.config.email_text_power_consumption) {
        return this.config.email_text_power_consumption.replace('{{signature}}', this.config.signature).trim()
      }
      return ''
    },
  },
  methods: {
    open() {
      this.$uikit.modal(this.$refs.modal).show()
      this.getConfig()
    },
    async getConfig() {
      const config = await ConfigService.get()
      this.config = config.data
    },
    async send() {
      this.submit = true
      try {
        await ProjectService.sendMail(this.documentId, this.requestType, {
          subject: this.form.subject,
          receivers: this.mailReceivers,
          message: this.form.message,
        })
        this.submit = false

        this.$uikit.modal(this.$refs.modal).hide()

        iziToast.success({
          position: 'topCenter',
          title: 'OK',
          message: this.identifierLabel + ' verschickt!',
        })
      } catch (error) {
        this.submit = false
        this.$uikit.modal(this.$refs.modal).hide()
        iziToast.error({
          position: 'topCenter',
          title: 'Fehler',
          message: 'Es ist ein Fehler aufgetreten!',
        })
      }
    },
  },
}
</script>
