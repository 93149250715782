<template>
  <form class="uk-form-stacked">
    <div class="uk-margin">
      <div class="uk-grid uk-grid-small uk-child-width-1-3@l uk-child-width-1-2@m" data-uk-grid>
        <div>
          <div class="uk-form-controls">
            <label><input class="uk-checkbox" type="checkbox" v-model="form.widerstandsmessgeraet"> Widerstandsmessgerät</label><br>
            <label><input class="uk-checkbox" type="checkbox" v-model="form.hochfrequenzmessgeraet"> Hochfrequenzmessgerät</label><br>
            <label><input class="uk-checkbox" type="checkbox" v-model="form.mikrowelle"> Mikrowelle</label>
          </div>
        </div>
        <div>
          <div class="uk-form-controls">
            <label><input class="uk-checkbox" type="checkbox" v-model="form.temperaturFeuchtigkeit"> Temperatur - Feuchtigkeit</label><br>
            <label><input class="uk-checkbox" type="checkbox" v-model="form.sonst1"> Sonstige 1</label>
            <div class="uk-form-controls">
              <div class="uk-inline uk-width-1-1">
                <input type="text" class="uk-input uk-border-rounded" v-model="form.sonst1V"
                :disabled="!form.sonst1">
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="uk-form-controls">
            <label><input class="uk-checkbox" type="checkbox" v-model="form.thermoAnemomete"> Thermo - Anemometer</label><br>
            <label><input class="uk-checkbox" type="checkbox" v-model="form.sonst2"> Sonstige 2</label>
            <div class="uk-form-controls">
              <div class="uk-inline uk-width-1-1">
                <input type="text" class="uk-input uk-border-rounded" v-model="form.sonst2V"
                :disabled="!form.sonst2">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
     <div class="uk-margin uk-width-small">
       <label class="uk-form-label">Abbaudatum</label>
       <div class="uk-form-controls">
         <date-picker
              mode="date"
              v-model="form.abbaudatum"
              :attributes="datepickerAttr"
              :popover="{ visibility: 'click' }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="uk-input uk-border-rounded"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </date-picker>
       </div>
     </div>
  </form>
</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
export default {
  props: {
    value: {
      required: true,
    },
  },
  components: {
    DatePicker
  },
  data() {
    return {
      datepickerAttr: [
        {
          dot: true,
          dates: new Date(),
        },
      ],
      form: {
        widerstandsmessgeraet: false,
        hochfrequenzmessgeraet: false,
        mikrowelle: false,
        temperaturFeuchtigkeit: false,
        sonst1: false,
        sonst1V: '',
        thermoAnemomete: false,
        sonst2: false,
        sonst2V: '',
      },
    }
  },
  mounted() {
    setTimeout(() => {
      this.form = Object.assign({}, this.form, this.value)
    }, 500)
  },
  watch: {
    form: {
      handler() {
        this.$emit('input', this.form)
      },
      deep: true,
    },
  },
}
</script>
