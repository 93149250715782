<template>
  <div>
    <div class="uk-grid" data-uk-grid>
      <div class="uk-width-1-1">
        <div class="uk-margin">
          <label class="uk-form-label">Anrede</label>
          <div class="uk-form-controls">
            <select class="uk-select uk-border-rounded" v-model="form.salutation">
              <option value="Herr">Herr</option>
              <option value="Frau">Frau</option>
            </select>
          </div>
        </div>
        <div class="uk-margin">
          <label class="uk-form-label">Name</label>
          <input class="uk-input uk-border-rounded" v-model="form.name" />
        </div>
        <div class="uk-margin">
          <label class="uk-form-label">Straße</label>
          <input class="uk-input uk-border-rounded" v-model="form.street" />
        </div>
        <div class="uk-margin">
          <div class="uk-grid uk-child-width-1-2@m" data-uk-grid>
            <div>
              <label class="uk-form-label">PLZ</label>
              <input class="uk-input uk-border-rounded" v-model="form.zipcode" />
            </div>
            <div>
              <label class="uk-form-label">Ort</label>
              <input class="uk-input uk-border-rounded" v-model="form.city" />
            </div>
          </div>
        </div>
        <div class="uk-margin">
          <label class="uk-form-label">E-Mail</label>
          <input class="uk-input uk-border-rounded" v-model="form.email" />
        </div>
      </div>
      <div class="uk-width-1-2@m">
        <label class="uk-form-label">Von</label>
        <date-picker mode="date" v-model="form.dateFrom" :attributes="datepickerAttr" :popover="{ visibility: 'click' }">
          <template v-slot="{ inputValue, inputEvents }">
            <input class="uk-input uk-border-rounded" :value="inputValue" v-on="inputEvents" />
          </template>
        </date-picker>
      </div>
      <div class="uk-width-1-2@m">
        <label class="uk-form-label">Bis</label>
        <date-picker mode="date" v-model="form.dateTo" :attributes="datepickerAttr" :popover="{ visibility: 'click' }">
          <template v-slot="{ inputValue, inputEvents }">
            <input class="uk-input uk-border-rounded" :value="inputValue" v-on="inputEvents" />
          </template>
        </date-picker>
      </div>
      <div class="uk-width-1-3@m">
        <label class="uk-form-label">Stromverbrauch (kwh)</label>
        <div class="uk-form-controls">
          <input type="text" class="uk-input uk-border-rounded" v-model="form.power" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
export default {
  props: {
    value: {
      required: true,
    },
  },
  data() {
    return {
      datepickerAttr: [
        {
          dot: true,
          dates: new Date(),
        },
      ],
      form: {
        salutation: '',
        name: '',
        street: '',
        zipcode: '',
        city: '',
        email: '',
        dateFrom: new Date(),
        dateTo: new Date(),
        power: '',
      },
    }
  },
  components: {
    DatePicker,
  },
  mounted() {
    setTimeout(() => {
      this.form = Object.assign({}, this.form, this.value)
    }, 500)
  },
  watch: {
    form: {
      handler() {
        this.$emit('input', this.form)
      },
      deep: true,
    },
  },
}
</script>
