<template>
  <form class="uk-form-stacked">
    <div class="uk-margin">
      <div class="uk-grid uk-grid-small" data-uk-grid>
        <div class="uk-width-1-2@m">
          <div class="uk-form-controls">
            <div class="uk-inline uk-width-1-1">
              <label class="uk-form-label">Name</label>
              <input type="text" class="uk-input uk-border-rounded" v-model="form.name">
            </div>
          </div>
        </div>
        <div class="uk-width-1-2@m">
            <div class="uk-form-controls">
            <label><input class="uk-checkbox" type="checkbox" v-model="form.digits"> Digits</label><br>
            <label><input class="uk-checkbox" type="checkbox" v-model="form.absoluteWerte"> absolute Werte (%)</label><br>
          </div>
        </div>
        <div class="uk-width-1-2@m">
          <div class="uk-form-controls">
            <label><input class="uk-checkbox" type="checkbox" v-model="form.boden"> Boden</label><br>
            <label><input class="uk-checkbox" type="checkbox" v-model="form.wand"> Wand</label><br>
            <label><input class="uk-checkbox" type="checkbox" v-model="form.raum"> Raum</label><br>
            <label><input class="uk-checkbox" type="checkbox" v-model="form.decke"> Decke</label>
          </div>
        </div>
        <div class="uk-width-small@m">
          <label class="uk-form-label">Etage</label>
          <div class="uk-form-controls">
            <v-select :options="['KG','EG','OG', 'DG']" v-model="form.etage" :clearable="false" :searchable="false"></v-select>
          </div>
        </div>
      </div>
    </div>
    <button class="uk-button uk-button-primary uk-button-small" @click.prevent="addTable"> 
      <span data-uk-icon="plus-circle"></span>
      Werte hinzufügen
    </button>
    <div class="uk-margin" v-for="(table, i) in form.table" :key="i">
    <div class="uk-grid uk-grid-small" ddta-uk-grid>
      <div class="uk-width-1-4@m">
          <label class="uk-form-label">Datum</label>
          <div class="uk-form-controls">
            <date-picker
              mode="date"
              v-model="table.date"
              :attributes="datepickerAttr"
              :popover="{ visibility: 'click' }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="uk-input uk-border-rounded"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </date-picker>
          </div>
        </div>
      <div class="uk-margin-1-6@m">
        <label class="uk-form-label">°C</label>
        <div class="uk-form-controls">
          <input
            class="uk-input uk-border-rounded"
            v-model="table.grad"
          />
        </div>
      </div>
      <div class="uk-margin-1-6@m">
        <label class="uk-form-label">%</label>
        <div class="uk-form-controls">
          <input
            class="uk-input uk-border-rounded"
            v-model="table.prozent"
          />
        </div>
      </div>
    </div>
    <div class="uk-margin">
    <div class="uk-grid uk-grid-small" data-uk-grid>
      <div class="uk-margin-1-6@m">
        <label class="uk-form-label">Mp1</label>
        <div class="uk-form-controls">
          <input
            class="uk-input uk-border-rounded"
            v-model="table.mp1"
          />
        </div>
      </div>
      <div class="uk-margin-1-6@m">
        <label class="uk-form-label">Mp2</label>
        <div class="uk-form-controls">
          <input
            class="uk-input uk-border-rounded"
            v-model="table.mp2"
          />
        </div>
      </div>
      <div class="uk-margin-1-6@m">
        <label class="uk-form-label">Mp3</label>
        <div class="uk-form-controls">
          <input
            class="uk-input uk-border-rounded"
            v-model="table.mp3"
          />
        </div>
      </div>
      <div class="uk-margin-1-6@m">
        <label class="uk-form-label">Mp4</label>
        <div class="uk-form-controls">
          <input
            class="uk-input uk-border-rounded"
            v-model="table.mp4"
          />
        </div>
      </div>
      <div class="uk-margin-1-6@m">
        <label class="uk-form-label">Mp5</label>
        <div class="uk-form-controls">
          <input
            class="uk-input uk-border-rounded"
            v-model="table.mp5"
          />
        </div>
      </div>
      <div class="uk-margin-1-6@m">
        <label class="uk-form-label">Mp6</label>
        <div class="uk-form-controls">
          <input
            class="uk-input uk-border-rounded"
            v-model="table.mp6"
          />
        </div>
      </div>
      <div class="uk-margin-1-6@m">
        <label class="uk-form-label">Mp7</label>
        <div class="uk-form-controls">
          <input
            class="uk-input uk-border-rounded"
            v-model="table.mp7"
          />
        </div>
      </div>
      <div class="uk-margin-1-6@m">
        <label class="uk-form-label">Mp8</label>
        <div class="uk-form-controls">
          <input
            class="uk-input uk-border-rounded"
            v-model="table.mp8"
          />
        </div>
      </div>
    </div>
    </div>
      <div class="uk-text-right">
        <button class="uk-button uk-button-link uk-text-danger uk-margin-right" @click.prevent="deleteTable(i)">
          Werte entfernen
        </button>
      </div>
    <hr>
    </div>
    <!--
    <div class="uk-margin" v-if="form.name">
      <div class="uk-width-xlarge@l">
        
        <UploadFile
          v-if="form.images && !form.images[0].path"
          :key="`measurementImage-${roomIndex}`"
          :url="uploadUrl"
          :params="{type: 'measurement', roomIndex: roomIndex}"
          @uploaded="onImageUpload"
        ></UploadFile>
        <measurement-image :image="form.images[0]" @stored="onImageStored"></measurement-image>
        </div>
    </div>
  -->
  </form>
</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
//import MeasurementImage from '@/components/protocol/MeasurementImage'
//import UploadFile from '@/components/UploadFile'
import 'izitoast/dist/css/iziToast.min.css'
import iziToast from 'izitoast/dist/js/iziToast.min.js'
import vSelect from 'vue-select'
export default {
  props: {
    value: {
      required: true,
    },
    uploadUrl: {
      required: true
    },
    roomIndex: {
      required: true
    }
  },
  data() {
    return {
      datepickerAttr: [
        {
          dot: true,
          dates: new Date(),
        },
      ],
      form: {
        name: '',
        digits: false,
        absoluteWerte: false,
        decke: false,
        boden: false,
        wand: false,
        raum: false,
        etage: null,
        images: [{id: null}],
        table: [{
          date: new Date(),
          grad: '',
          prozent: '',
          mp1: '',
          mp2: '',
          mp3: '',
          mp4: '',
          mp5: '',
          mp6: '',
          mp7: '',
          mp8: '',
        }]
      },
    }
  },
  components: {
    DatePicker,
    //UploadFile,
    //MeasurementImage,
    vSelect
  },
  mounted() {
    setTimeout(() => {
      this.form = Object.assign({}, this.form, this.value)
    }, 500)
  },
  watch: {
    form: {
      handler() {
        this.$emit('input', this.form)
      },
      deep: true,
    },
  },
  methods: {
    addTable() {
      this.form.table.push({
        date: new Date(),
        grad: '18',
        prozent: '60',
        mp1: '',
        mp2: '',
        mp3: '',  
        mp4: '',
        mp5: '',
        mp6: '',
        mp7: '',
        mp8: '',
      })
    },
    deleteTable(index) {
      this.form.table.splice(index, 1)
    },
    addImage() {
      this.form.images[0] = {
        id: null,
        path: null
      }
    },
    onImageUpload() {
      this.$emit('imageUploaded')
      setTimeout(() => {
        this.form = Object.assign({}, this.form, this.value)
      }, 500)
    },
    onImageStored() {
      iziToast.success({
        title: 'OK',
          message: 'Bild gespeichert!',
          timeout: 3000,
          position: 'bottomRight',
          transitionIn: 'fadeInLeft',
        })
    }
  }
}
</script>
